import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";

import { Grid } from "react-flexbox-grid";
import Seo from "./seo";
import Header from "./Header";
import Footer from "./Footer";
import { ThemeProvider, CSSReset } from "@chakra-ui/react";

const Layout = ({ children, title }) => {
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                }
            }
            allMarkdownRemark(
                filter: {fileAbsolutePath: {regex: "/posts/.*.md$/"}}
                limit: 4
                sort: {order: DESC, fields: frontmatter___date}
            ) {
                nodes {
                    frontmatter {
                      title
                    }
                  }
            }
            # localSearchPages {
            #     index
            #     store
            # }
        }
    `);

    return (
        <ThemeProvider>
            <Grid fluid className="layout">
                <CSSReset />
                <Seo title={title ? title : null} />
                <div className="site-content">
                    <Header
                        siteTitle={data.site.siteMetadata.title}
                        navItems={navItems}
                        // searchIndex={data.localSearchPages.index}
                        // searchStore={data.localSearchPages.store}
                    />
                    {/* <div className="layout-content-wrapper">{children}</div> */}
                    <div>{children}</div>
                </div>
                <Footer
                    siteTitle={data.site.siteMetadata.title}
                    recents={data.allSitePage ? data.allSitePage.nodes : null}
                    navItems={navItems}
                />
            </Grid>
        </ThemeProvider>
    );
};

Layout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Layout;

const navItems = [
    // { name: "Posts", path: "/posts" },
    { name: "Gallery", path: "/gallery" },
];
