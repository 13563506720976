import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import { Grid, Row, Col } from "react-flexbox-grid";
import Navigation from "./Navigation";
import Search from "./Search";

const Header = ({ siteTitle, navItems }) => {
    return (
        <header className="header">
            <Grid fluid className="wrapper">
                <Row>
                    <Col xs={8} md={4}>
                        <Link to="/" className="title-container">
                            <span className="title">{siteTitle}</span>
                        </Link>
                    </Col>
                    <Col md={4} className="search">
                        <Search />
                    </Col>
                    <Col xs={4} md={4} xsOffset={0} mdOffset={0}>
                        <Navigation siteTitle={siteTitle} navItems={navItems} />
                    </Col>
                </Row>
            </Grid>
        </header>
    );
};

Header.propTypes = {
    siteTitle: PropTypes.string,
};

Header.defaultProps = {
    siteTitle: ``,
};

export default Header;

/* <div className="wave-container">
    <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{ height: "100%", width: "100%" }}>
        <defs>
            <linearGradient id="header-gradient">
                <stop offset="0%" stopColor="var(--stop-color-1)" />
                <stop offset="100%" stopColor="var(--stop-color-2)" />
            </linearGradient>
        </defs>
        <path
            d="M-14.11,31.08 C158.01,55.75 317.71,-40.97 520.87,48.83 L500.00,0.00 L0.00,0.00 Z"
            className="header-wave"
        ></path>
    </svg>
</div>; */
