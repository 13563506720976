import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { useFormikContext, Formik, Field, Form } from "formik";
import { useFlexSearch } from "react-use-flexsearch";
import debounce from "just-debounce-it";
import { Link } from "gatsby";
import { Input } from "@chakra-ui/react";

const Search = () => {
    const data = useStaticQuery(graphql`
        {
            localSearchPages {
                index
                store
            }
        }
    `);
    const [query, setQuery] = useState(null);
    const results = useFlexSearch(query, data.localSearchPages.index, data.localSearchPages.store);
    // console.log("results: ", results);
    return (
        <Formik
            initialValues={{ query: "" }}
            onSubmit={(values, { setSubmitting }) => {
                setQuery(values.query);
                setSubmitting(false);
            }}
        >
            {(props) => {
                return (
                    <Form>
                        <Field name="query" id="query" placeholder="Search...">
                            {({ field: { value }, form: { setFieldValue } }) => (
                                <Input
                                    name="query"
                                    variant="filled"
                                    placeholder="Search..."
                                    value={value}
                                    onChange={(e) => setFieldValue("query", e.target.value)}
                                />
                            )}
                        </Field>
                        <AutoSave debounceMs={300} />
                        <div className="results">
                            {results && results.length > 0
                                ? results.map((result, index) => {
                                      console.log(result);
                                      return (
                                          <Link to={result.path} key={index} className="result">
                                              {result.title}
                                          </Link>
                                      );
                                  })
                                : null}
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default Search;

const AutoSave = ({ debounceMs }) => {
    const formik = useFormikContext();
    // const [lastSaved, setLastSaved] = React.useState(null);
    const debouncedSubmit = React.useCallback(
        debounce(
            () => formik.submitForm(),
            // .then(() => setLastSaved(new Date().toISOString())),
            debounceMs
        ),
        [debounceMs, formik.submitForm]
    );

    React.useEffect(() => {
        debouncedSubmit();
    }, [debouncedSubmit, formik.values]);
    return null;
    // return (
    //     <>
    //         {!!formik.isSubmitting
    //             ? "saving..."
    //             : lastSaved !== null
    //             ? `Last Saved: ${lastSaved}`
    //             : null}
    //     </>
    // );
};
