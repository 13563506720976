import styled from "styled-components";

export const Title = styled.span`
    font-family: "Playfair Display", serif;
    font-weight: 700;
    font-size: 24pt;
`;

/* wraps the whole page content */
export const CenterLayout = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: center;
    margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom + "rem !important" : 0)};
    margin: 0 auto;
    width: ${(props) => (props.width ? props.width + "%": "100%")};
    justify-content: ${(props) => (props.justifyContent ? props.justifyContent : null)};
    @media screen and (min-width: 768px) {
        align-items: center;
        width: ${(props) => (props.width ? props.width + "%" : "100%")};
        max-width: ${(props) => (props.maxWidth ? props.maxWidth + "px" : "960px")};
    }
`;

export const Sections = styled.div`
    flex: 1;
    width: 100%;
    display: grid;

    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(${(props) => props.rows}, 1fr);
    justify-items: center;
`;

export const Section = styled.div`
    /* background-color: ${(props) => (props.bg ? props.bg : null)}; */
    background-color: ${(props) => (props.index % 2 === 0 ? "#e8eaf0" : "#fff")};
    width: 100%;
    /* padding: 1.875rem 0 1.875rem; */
    padding: ${(props) => (props.nopadding ? null : "1.875rem 0 1.875rem")};
    position: ${(props) => (props.relative ? "relative" : null)};
    height: ${(props) => (props.height ? props.height : null)};
    @media screen and (min-width: 768px) {
        /* padding: calc(1.875rem * 4) 0 calc(1.875rem * 4); */
        padding: ${(props) => (props.nopadding ? null : "calc(1.875rem * 4) 0 calc(1.875rem * 4)")};
    }
`;

export const Rule = styled.div`
    height: 3px;
    width: 40%;
    /* background: linear-gradient(90deg, $lavender-blue, $lavender); */
    /* background-color: ${(props) => (props.color ? props.color : null)}; */
    background-color: ${(props) => (props.index % 2 !== 0 ? "#fff" : "#e8eaf0")};
    margin: calc(1.875rem / 8) 0 1.875rem;
`;

export const SectionSelector = styled.div`
    display: flex;
    justify-content: space-evenly;
    top: calc(1.875rem * 2.5);
    width: 100%;
    height: 40px;
    position: fixed;
    z-index: 2;
    @media screen and (min-width: 768px) {
        width: 50%;
    }
    & > div {
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 4px solid transparent;
        position: relative;
        cursor: pointer;
        padding: 0 5px 0;

        &.selected {
            background-color: hsla(226, 21%, 76%, 50%);
        }

        &.selected:before {
            width: 100%;
        }

        &.selected > span {
            opacity: 1;
        }

        &:hover > span {
            opacity: 1;
        }

        &::before {
            content: "";
            width: 0;
            height: 4px;
            background: #b5bbcf;
            position: absolute;
            left: 0;
            bottom: -4px;
            transition: width 0.3s ease-out;
            opacity: 1 !important;
        }

        &:hover:before {
            /* border-bottom: 4px solid #a7b4a6; */
            width: 100%;
        }

        & > span {
            font-family: "Roboto", sans-serif;
            color: #262b30;
            opacity: 0.75;
            text-transform: uppercase;
            font-weight: 300;
            margin-top: 4px;
        }
    }
`;
