import React, { useState } from "react";
import { Link } from "gatsby";
import { Menu } from "react-feather";
import Button from "@material-ui/core/Button";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";

const Navigation = ({ siteTitle, navItems }) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggleDrawer = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="navigation-wrapper">
            <div className="navigation-item-wrapper">
                {navItems.map(({ name, path }, index) => {
                    return (
                        <Link to={path} className="item" key={index}>
                            <span>{name}</span>
                        </Link>
                    );
                })}
            </div>
            <Button onClick={toggleDrawer} className="burger">
                <Menu className="item menu" />
            </Button>
            <SwipeableDrawer
                anchor="right"
                open={isOpen}
                onClose={toggleDrawer}
                onOpen={toggleDrawer}
                className="drawer"
            >
                <div className="mobile-navigation-wrapper">
                    <div className="mobile-navigation-title">
                        <Link to="/">{siteTitle}</Link>
                    </div>
                    {navItems.map(({ name, path }, index) => {
                        return (
                            <Link to={path} className="item" key={index}>
                                {name}
                            </Link>
                        );
                    })}
                </div>
            </SwipeableDrawer>
        </div>
    );
};

export default Navigation;
