import React from "react";
import { Link } from "gatsby";

const Footer = ({ siteTitle, recents, navItems }) => {
    return (
        // <footer className="footer2">
        //     <Grid>
        //         <Row>
        //             <Col className="legal-items" xs={6}>
        //                 {footerItems.map(({ name, path }, index) => {
        //                     return (
        //                         <Link to={path} key={index} className="item">
        //                             {name}
        //                         </Link>
        //                     );
        //                 })}
        //             </Col>
        // <Col className="gatsby" xs={6}>
        //     <span>
        //         © {new Date().getFullYear()}, Built with
        //         {` `}
        //         <a href="https://www.gatsbyjs.org">Gatsby</a>
        //     </span>
        // </Col>
        //         </Row>
        //     </Grid>
        // </footer>
        <footer className="footer">
            <div className="wrapper">
                <div className="recent-posts">
                    <div>
                        <span>Recent Posts</span>
                        <div>
                            {recents &&
                                recents.map((post, index) => {
                                    return (
                                        <Link to={post.path} key={post.path}>
                                            <span>{post.context.frontmatter.title}</span>
                                        </Link>
                                    );
                                })}
                        </div>
                    </div>
                </div>
                <div className="site-links">
                    <div>
                        {navItems &&
                            navItems.map((item, index) => (
                                <div key={index}>
                                    <Link to={item.path}>{item.name}</Link>
                                </div>
                            ))}
                    </div>
                </div>
                <div className="gatsby">
                    <span>
                        © {siteTitle + " " + new Date().getFullYear()}, Built with
                        {` `}
                        <a href="https://www.gatsbyjs.org">Gatsby</a>
                    </span>
                </div>
                <div className="legal-items">
                    {footerItems.map(({ name, path }, index) => {
                        return (
                            <Link to={path} key={index} className="item">
                                {name}
                            </Link>
                        );
                    })}
                </div>
            </div>
        </footer>
    );
};

export default Footer;

const footerItems = [
    { name: "Contact", path: "/contact" },
    { name: "Privacy Policy", path: "/privacy-policy" },
    { name: "Terms of Use", path: "/terms-of-use" },
];
